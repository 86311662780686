













































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {required} from '../../utils/validationRules';
import TranslationService from '../../services/TranslationService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';

@Component({
  name: 'LabelForm',
})
export default class LabelForm extends Vue {
  @Prop()
  value!: boolean;

  label: string = '';

  loading: boolean = false;

  rules = {
    label: [required],
  };

  @Emit('input')
  toggleVisibility() {
    // @ts-ignore
    this.$refs.form.reset();
    return !this.value;
  }

  async createLabel() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      try {
        await TranslationService.createLabel(this.label);
        notify(NotificationTypes.success, this.$t('SYSTEM_SAVED'));
        this.$emit('saved');
        this.toggleVisibility();
      } catch (e) {
        resolveError(e, 'save');
      }
    }
  }
}
